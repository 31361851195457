import React , {useContext, useEffect, useState} from 'react';
import productList from '../../data/productList';
import Productos from "../../view/products";
import AppContext from '../../context/AppContext';
import ProductoItem from "../../view/productItem";
import { useCookies } from 'react-cookie';
import ProductoItemAdmin from '../../view/productItemAdmin';



export default function MenuAdmin() {
  const { api, cart, deleteCartById, restToCart, link, setClick } = useContext(AppContext);
  const [cant, setcant] = useState(cart.card.length);

    const [currentPage, setCurrentPage] = useState(0);
    const [usuario] = useCookies(['usuario']);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let category = " "
    const fetchData = async () => {
        const result = await getData();
      
        setData(result);
        setIsLoading(true)
        };



const getData = async () => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idusuario: usuario.usuario.id })
          };
        
        const response = await fetch(`${api}/api/productos/`, requestOptions);
        const result = await response.json();

        async function fetchCategorias() {
                 
                
            const response = await fetch(`${api}/api/categorias/`, requestOptions);
            
            const data = await response.json();
            setCategorias(data.map((categoria => ({ nombre: categoria.nombre, id: categoria.id })  )));
           
          }

        
          fetchCategorias();
        const dataWithImageUrls = await Promise.all(
            result.map(async (item) => { 
                
                return item;
              })
            );        
          return dataWithImageUrls;
 
   
    } catch (error) {
      window.location.replace('/Admin/error')
    }
};

      useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);



  const onChangeLinkMenu = () => {
    setClick();
}

function addToCant(cant) {
 return setcant;
}



const sumaTotal = () => {
    const reducer = (accumalator,  currentValue) => accumalator + currentValue.price*currentValue.qty;
    const sum = cart.card.reduce(reducer, 0)
    return sum;
}

const onChangeActive = event => {
  document.querySelectorAll('.activeNav').forEach(function (item) { item.classList.remove('activeNav'); });
  // 👇️ toggle className on click
  event.currentTarget.classList.add('activeNav');

}
const [categoria, setCategoria] = useState("todos");

const elementosFiltrados = data.filter(
  elemento => categoria === "todos" || elemento.idcategoria === categoria
);

elementosFiltrados.sort((a, b) => a.idcategoria - b.idcategoria);



  return (

    <section id="menu" className=" sectionMenu menu section-bg">

      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Menú</h2>
        </div>
       
       { isLoading  ?
       <>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12 d-flex justify-content-center">
                         
            <ul id="menu-flters">

              {categorias.map(props=> 
                <li key={props.id} className={props.id === categoria.id ? "filter-active" : ""} onClick={() => setCategoria(props.id)}>{props.nombre}</li>)}
              <li className={categoria === "todos" ? "filter-active" : ""} onClick={() => setCategoria("todos")}>Todos</li>
            </ul>
          </div>
        </div>
            
        <div id="pfilter" className="row menu-container" data-aos="fade-up" data-aos-delay="200">
          { elementosFiltrados.map((product => {
             categorias.map(categ=> 
              categ.id === product.idcategoria ?  category = categ.nombre : " ")
              return <Productos require  key={product.id} img={product.img} categoria={category} nombre={product.nombre} price={product.precio} ingredients={product.ingredientes} id={product.id}></Productos>;  
                             
            
            })
                          )  }   
                              
      </div>
    </> : <div id="preloader"></div> }
    </div>
    {cart.card.length > 0 ? 

    <div id='inf'>
       <button className='total' onClick={onChangeLinkMenu}>   <p className="subtotal pedido">Ver Pedido ({  cart.card.length })</p><strong/>
                <p className="subtotal cartTotal pedido">$ {sumaTotal().toFixed(2)}</p></button>
    </div>: null}

 

    <div id="mobile">


                        </div>
                        <div >
                            <ul id="carrito" className={link ? "active":""  }>
                                <li className="cabezal">
                                    <span id="closeCar" onClick={onChangeLinkMenu} ><span translate="no" className="material-icons-outlined">
                                        close
                                    </span></span> <span id="title">
                                        <h4>Mi Pedido</h4>
                                    </span> <span ></span>
                                </li>

                               <ul id="pedido">
                                        <ProductoItemAdmin></ProductoItemAdmin>
                               </ul>
                                    
                                  
                              

                                


                            </ul>
                            {/* <div className={link ? "opacar visible" : "opacar "}></div> */}
                           
                        </div>
                    {/*  <div id="preloader"></div>*/} 
      </section >  

      
    )
}