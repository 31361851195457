import React, { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { initMercadoPago, Payment, Wallet } from '@mercadopago/sdk-react';
import AppContext from "../context/AppContext";
import { useState } from "react";
import { useCookies } from "react-cookie";


export default function PedidosMenu() {
  const [pedidos, setPedidos] = useState([]);
  const [ubicacion, setUbicacion] = useState([]);
  const [pproductos, setPproductos] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usuario] = useCookies(['usuario']);
  const { active, api, setClick, total } = useContext(AppContext);
  const [porcentaje, setPorcentaje] = useState(0);
  const serweb = (porcentaje === 0)  ? 'Bonificado' : '$' + (total / (porcentaje * 100)).toFixed(1);
  const sumTotal = (porcentaje === 0)  ? total : (Number(serweb.replace('$', '')) + total).toFixed(1);
  const currentUrl = window.location.href;
  const rejected = currentUrl.includes('rejected');
  const isLocalUrl =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const handleOptionSelect = (id, tiempo, estado, idusuario) => {
    setSelectedOption({
      id: id,
      tiempoespera: tiempo,
      estado: estado,
      idusuario: idusuario,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);




  useEffect(() => {
    let jsonpedidos = [];
    let pedi = [];

    const userId = usuario.usuario.id;

    let apiUrl = "";
    let endpoint = "";

    if (isLocalUrl) {
      apiUrl = "localhost:8093";
      endpoint = `ws:${apiUrl}/myHandler?userid=${userId}`;
      console.log(userId);
    } else {
      apiUrl = "app.clickmenu.com.ar:8093";
      endpoint = `wss:${apiUrl}/myHandler?userid=${userId}`;
    }

    const socket = new WebSocket(endpoint);
    socket.onopen = function () {
      console.log("WebSocket connection established");
    };
    socket.addEventListener("open", () => {
      console.log("WebSocket connection established");
    });
    try {
      socket.addEventListener("message", (event) => {
        const pedido = JSON.parse(event.data);
        if (pedido.pedidos) {
          jsonpedidos = pedido.pedidos;
          setPedidos(jsonpedidos);

          pedido.ubicaciones && setUbicacion(pedido.ubicaciones);

          const productosArray = pedido.pproductos.map((producto) => {
            return {
              idpedido: producto.pedidoProductoPK.idpedido,
              idproducto: producto.pedidoProductoPK.idproducto,
              nombre: producto.producto,
              cantidad: producto.cantidad,
              precio: producto.precio,
              estado: producto.estado,
              idusuario: producto.idusuario,
              idmesa: producto.idmesa,
              cliente: producto.nombrecliente,
              observacion: producto.observacion,
            };
          });
          //jsonproducto = JSON.stringify(pedido.pproductos);
          setPproductos(productosArray);

          pedi = pedido.pedidos;
          setPedidos(Object.values(pedi));
          /*const audio = new Audio(soundFile);

          // Reproduce el sonido cuando el componente se monta
          audio.play().catch((error) => {
            console.error("Audio playback failed:", error);
          });return () => audio.pause();
         */
          setLoading(false); // Actualiza el estado de carga

          
        } else {
          jsonpedidos = pedido;
          setPedidos(jsonpedidos);

          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error al analizar el JSON:", error);
    }
  }, [selectedOption]);

  const elementosFiltrados = pedidos.sort((a, b) => {
    if (a.Mesa === 1 && b.Mesa !== 1) {
      return -1;
    } else if (a.Mesa !== 1 && b.Mesa === 1) {
      return 1;
    } else {
      return 0;
    }
  });


  return (
    <section id="contact" className="sectionMenu section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Mis Pedidos Activos</h2>
          <p>PEDIDOS</p>
        </div>
      </div>


      <div className="container" data-aos="fade-up">
   {/*
        <div className="alert alert-warning section-bg" role="alert">
          <h6>¡Estás a un paso de abonarlo!</h6>
        </div>
      <div id="cart" className="section-p1">
          < width="100%">
            <thead>
              <tr>
                <td>Imagén</td>
                <td>Producto</td>
                <td>Precio</td>
                <td>Cantidad</td>
                <td id="oculta">Total</td>
              </tr>
            </thead>
            <tdbody>
              <tr>
                <td><img src="img/products/f1.jpg" alt="" /></td>
                <td>NombreProducto</td>
                <td>$ 3333.55</td>
                <td><input type="number" name="" id="" value="1" /></td>
                <td id="oculta">$ 3333.55</td>
              </tr>
              <tr>
                <td><img src="img/products/f2.jpg" alt="" /></td>
                <td>NombreProducto</td>
                <td>$ 3333.5</td>
                <td><input type="number" name="" id="" value="1" /></td>
                <td id="oculta">$ 3333.55</td>
              </tr>
              <tr>
            
                <td><img src="img/products/f3.jpg" alt="" /></td>
                <td>NombreProducto</td>
                <td>$ 3333.55</td>
                <td><input type="number" name="" id="" value="1" /></td>
                <td id="oculta">$ 3.333.55</td>
              </tr>
            </tdbody>
          </>
        </div> */}
        <br></br>
        <div className="accordion" id="accordionExample">

        {elementosFiltrados.map((pedido, id) => {
          const collapseId = `collapse-${pedido.id}`;
          const headerId = `heading-${pedido.id}`;
          
          return (
            <div className="accordion-item" key={id}>
      <h2 className="accordion-header" id={headerId}>
        <button
          className="accordion-button grid collapsed" // Añadir "collapsed" para que el botón se vea en estado colapsado
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${collapseId}`}
          aria-expanded="false" // Cambiar a false para que inicie colapsado
          aria-controls={collapseId}
        >
          <div>
            <strong>{pedido?.mesa !== 0 && `MESA ${pedido.mesa}`}</strong>
            {" | " + pedido.nombrecliente + " - Ped. #" + pedido.numeropedido}
          </div>
          <h6 className="price ms-auto grid">
            Total 
           <strong className="priceTotal">$
            {pedidos
              .find((pedidoo) => pedidoo.id === pedido.id)
              .consumototal.toLocaleString("es-AR")}{" "}</strong> 
          </h6>
        </button>
      </h2>
      <div
        id={collapseId}
        className="accordion-collapse collapse" // Quitar la clase "show" para que inicie colapsado
        aria-labelledby={headerId}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          {pproductos
            .filter((pp) => pp.idpedido === pedido.id)
            .map((product) => (
              <ul className="factu" key={product.id}>
                <li>{product.cantidad}x</li>
                <li>{product.nombre}</li>
                <li>${product.precio * product.cantidad}</li>
                <li>
                  <h6>{product.observacion}</h6>
                </li>
              </ul>
            ))}
          {pedido.comentario && (
            <div className="center cursiva observacion">
              Observación: <h6>{pedido.comentario}</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
})}


                    
                    </div>
       <div id="cart-add" className="section-p1 center">


    {/*       <div id="subtotal" className="align-right">
            {rejected ? <div id="subtotal" className="align-right">
              <h4 className="center"><i className="bi bi-x-circle"></i>Pago Rechazado</h4>
            </div> : <></>}

            <h4 className="center">Tu Resumen</h4>
    
              <tr>
                <td>Subtotal</td>
                <td className="precios">${  typeof total === 'number' ? total.toFixed(2) : ''}</td>
              </tr>
              <tr>
                <td><h6>Servicio web </h6></td>

          
                <td className="precios"><h6>{serweb}</h6> </td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td className="precios"><strong>${  typeof sumTotal === 'number' ? sumTotal.toFixed(2) : ''}</strong></td>
              </tr>
              <tr>

             
              </tr>
       

            <div className="grid martop4">
            <span className=""> <input type="text" placeholder="Ingresa tu Cupón" className='cupon' />
              <button className="buttonMenu ">Agregar</button></span>
            </div>
            <button className="normal second topbot" data-bs-toggle="modal" data-bs-target='#exampleModal'>Formas de Pagos</button>
           
          </div>
 */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content modal-contentMenu">
                <div className="modal-header">
                  <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

                </div>
                <div className="modal-body modal-bodyMenu">

                  <form >


                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Pago Efectivo
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con efectivo.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Pago Tarjeta Debito-Crédito
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con tu tarjeta de debito o crédito.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Transferencias - Envio Dinero MP
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Sólo se acepta transferencia directa desde el mismo banco emisor al banco receptor.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>

              </div></div>

          </div>

          {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-contentMenu">
              <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
          
               
              </div></div>

          </div> */}

         
        </div>
      </div>
    </section>
  )
}