import React, { useContext, useEffect, useState } from 'react';
import { Link, } from 'react-router-dom';
import productList from '../data/productList';
import Productos from "../view/products";
import AppContext from '../context/AppContext';
import ProductoItem from "../view/productItem";
import { useCookies } from 'react-cookie';



export default function Locales() {
  const { addToCart, cart, deleteCartById, restToCart } = useContext(AppContext);
  const [cant, setcant] = useState(cart.card.length);
  const [link, setLink] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [usuario, setUsuario] = useCookies(['usuario']);
  const [totalPages, setTotalPages] = useState(1);
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [comentario, setComentario] = useState('');

  const fetchData = async () => {
    try {
      const result = await getData();

      setData(result);
      setIsLoading(true)
    } catch (error) {
      window.location.replace('/error')
    }
  };

  useEffect(() => {

    
    const fetchUsuario = async () => {
  try {
    const response = await fetch(`${api}/api/usuarios/name/ClickMenu`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: "ClickMenu" }) // Datos a enviar en el cuerpo de la solicitud
    });
    const data = await response.json();
    setUsuario('usuario', data, { path: '/', maxAge: 604800 });


  } catch (error) {
    console.log('Error al obtener el usuario:', error);
  }
};
fetchUsuario();


}, []);


  const getData = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idusuario: 1 })
      };

      const response = await fetch(`${api}/api/productos/`, requestOptions);
      const result = await response.json();
      setData(result);

      async function fetchCategorias() {


        const response = await fetch(`${api}/api/categorias/`, requestOptions);
        const datacategoria = await response.json();
        setCategorias(datacategoria.map((categoria => ({ nombre: categoria.nombre, id: categoria.id }))));

      }


      fetchCategorias();
      const dataWithImageUrls = await Promise.all(
        result.map(async (item) => {

          return item;
        })
      );
      return dataWithImageUrls;


    } catch (error) {
      window.location.replace('/error')
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  const onChangeLinkMenu = function () {
    setLink(!link);
  }

  function addToCant(cant) {
    return setcant;
  }



  const sumaTotal = () => {
    const reducer = (accumalator, currentValue) => accumalator + currentValue.price * currentValue.qty;
    const sum = cart.card.reduce(reducer, 0)
    return sum;
  }

  const onChangeActive = event => {
    document.querySelectorAll('.activeNav').forEach(function (item) { item.classList.remove('activeNav'); });
    // 👇️ toggle className on click
    event.currentTarget.classList.add('activeNav');

  }
  const [categoria, setCategoria] = useState("todos");

  const elementosFiltrados = data.filter(
    elemento => categoria === "todos" || elemento.idcategoria === categoria
  );


  return (

    <section id="menu" className=" sectionMenu menu section-bg">

      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Locales</h2>
          <p>Nuestros Locales</p>
        </div>

        {isLoading ?
          <>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">

                <ul id="menu-flters">

                  {categorias.map(props =>
                    <li key={props.id}  className={props.id === categoria.id ? "filter-active" : ""} onClick={() => setCategoria(props.id)}>{props.nombre}</li>)}
                  <li className={categoria === "todos" ? "filter-active" : ""} onClick={() => setCategoria("todos")}>Todos</li>
                </ul>
              </div>
            </div>

            <div id="pfilter" className="row menu-container" data-aos="fade-up" data-aos-delay="200">
              {elementosFiltrados.map((props =>

                <div key={props.id}  className="col-lg-6 menu-item" data-categoria={props.categoria}>
                  <img src={props.img} className="menu-img" data-bs-toggle="modal" data-bs-target={`#exampleModal-${props.id}`} />
                  <span className="categoriaMenu">{props.idcategoria === categoria.id ? categoria.nombre : ""}</span>

                  <Link to={`/${props.nombre}`}>
                  <div className="menu-content">
                    <span>{props.nombre}</span> <span className='white'>ENTRAR</span>
                  </div>

                 
                    <div className="menu-ingredients">
                      {props.ingredientes}  <i className="bi bi-cursor-fill" id="addVisit"></i>
                    </div>
                  </Link>

                  <div className="modal fade" id={`exampleModal-${props.id}`} tabIndex="-1" aria-labelledby={`exampleModalLabel-${props.id}`} aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content modal-contentMenu">
                        <div className="modal-header">
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body modal-bodyMenu">
                          <div className="accordion accordion-flush" id={`accordionFlushExample-${props.id}`}>

                            <div className="gslide-media gslide-image">
                              <img src={props.img} className="zoomable imgModal" data-bs-toggle="modal" data-bs-target={`#exampleModal-${props.id}`} />
                            </div>
                            <div className="flex">
                              <h4 className='colorprimaryMenu'>{props.nombre}</h4>
                            </div>
                            <p className="menu">{props.ingredientes}</p>
                            <div className="form-group mt-3">
                              <textarea className="form-control" name="message" rows="2" placeholder="Comentario" onChange={(e) => setComentario(e.target.value)}></textarea>
                            </div>

                            <div className="center colorprimary">





                            </div>

                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )
              )}

            </div>
          </> : <div id="preloader"></div>}
      </div>
      {cart.card.length > 0 ?

        <div id='inf'>
          <button className='total' onClick={onChangeLinkMenu}>   <p className="subtotal pedido">Ver Pedido ({cart.card.length})</p><strong />
            <p className="subtotal cartTotal pedido">$ {sumaTotal().toFixed(2)}</p></button>
        </div> : null}


      <div id="mobile">
        <li><span id="shopMobile"><span className="material-icons-outlined">shopping_bag</span></span></li>
        <li> <span id="bar" className="material-icons-outlined">
          subject
        </span></li>

      </div>
      <div id="context">
        <ul id="carrito" className={link ? "active" : ""}>
          <li className="cabezal">
            <span id="closeCar" onClick={onChangeLinkMenu} ><span translate="no" className="material-icons-outlined">
              close
            </span></span> <span href="" id="title">
              <h4>Mi Pedido</h4>
            </span> <span ></span>
          </li>

          <ul id="pedido">
            <ProductoItem></ProductoItem>
          </ul>







        </ul>
        <div className={link ? "opacar visible" : "opacar "}></div>
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center active"><i className="bi bi-arrow-up-short"></i></a>
      </div>
      {/*  <div id="preloader"></div>*/}
    </section >


  )
}