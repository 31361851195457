
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../../context/AppContext";
import { notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Adicionales from "../../components/adicionales";

export default function ProductsAlta() {


  const { active, api } = useContext(AppContext);
  const [categorias, setCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState("");
  const [usuario] = useCookies(['usuario']);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sintacc, setSintacc] = useState(false);
  const [opcional, setOpcional] = useState(false);


  useEffect(() => {
  async function fetchCategorias() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ idusuario: usuario.usuario.id})
    };

    const response = await fetch(`${api}/api/categorias/`, requestOptions);
    const data = await response.json();
    setCategorias(data);
  }
  fetchCategorias();

}, []);

 

  const handleSubmit = async (event) => {
    event.preventDefault();


    if(opcional && selectedItems.length === 0){
      notification.open({
        message: "Notificacion: Extra o Requerido",
        description: "Debe seleccionar entre la lista de ingrediente.",
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
      });
      return;
    }

debugger
    const formData = new FormData();
    formData.append('nombre', event.target.nombre.value);
    formData.append('ingredientes', event.target.ingredientes.value);
    formData.append('precio', event.target.precio.value);
    formData.append('imgs', event.target.img.files[0]);
    formData.append('stock', event.target.stock.value);
    formData.append('idcategoria', categoriaId);
    formData.append('usuario', usuario.usuario.id);
    formData.append('activo', event.target.activo.checked);
    formData.append('sintacc', event.target.tacc.checked);
    formData.append('ingrediente', selectedItems);
    formData.append('adicionales', event.target.extra.checked);



    const getData = async () => {


      try {
        const response = await axios.post(`${api}/api/productos/alta`, formData);
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          notification.open({
            message: 'Notificación',
            description: 'Registro exitoso',
            icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
          });
          event.target.reset();
        }
        setOpcional(false)
      } catch (error) {
        notification.open({
          message: "Notificacion ",
          description: "Ocurrio un error 😢",
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        });
        console.log(error)
      }
    }


    await getData();

  };



  /*------------------------Listado Ingrediente-------------------------*/




  // Función que actualiza el estado en el padre
  const handleSelectedItemsUpdate = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  }
  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      {/* <div className="pagetitle">
        <nav>
          <ol className="breadcrumb"> <h1>Altas Productos</h1>
        </ol>
        </nav>
      </div> 

      <span className="rigth"><i className="bi bi-arrow-return-left"></i></span>*/}
      <section className="section">
        <div className="row">
          <div className="col-lg-8">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Alta Producto</h5>


                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">Activo</label>
                    <div className="col-sm-5 form-check form-switch">
                      <input className="form-check-input" type="checkbox" name="activo" id="gridCheck2" value="true" checked="true" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label" >Nombre</label>
                    <div className="col-sm-5">
                      <input type="text" className="form-control" name="nombre" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputEmail" className="col-sm-2 col-form-label" >Ingredientes</label>

                    <div className="col-sm-5">
                      <textarea className="form-control" id="floatingTextarea" name="ingredientes" required ></textarea>
                      {/* placeholder="Descripciones"  */}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Precio</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" name="precio" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="formFile" className="col-sm-2 col-form-label">Imagén</label>
                    <div className="col-sm-5">
                      <input className="form-control" type="file" id="formFile" name="img" accept="image/*" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Stock</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" name="stock" />
                    </div>
                    {/* <input type="hidden" name="idUsuario" value="17" /> */}
                  </div>

                  <div className="row mb-3">
                    <label for="inputDate" className="col-sm-2 col-form-label">Categoría</label>
                    <div className="col-sm-5">
                      <select className="form-select" aria-label="Default select example" name="idcategoria" onChange={(e) => setCategoriaId(e.target.value)} required >
                        <option value="" className="gris">Elegir una categoría</option>
                        {categorias.map((categoria) => (
                          <option key={categoria.id} value={categoria.id}>{categoria.nombre}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-3 col-form-label">Sin TACC</label>
                    <div className="col-sm-4 form-check form-switch">
                      <input className="form-check-input" type="checkbox" name="tacc" id="gridCheck2"
                        value={sintacc}
                        onChange={(e) => setSintacc(e.target.checked)} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-3 col-form-label"> Extras / Requeridos</label>
                    <div className="col-sm-4 form-check form-switch">
                      <input className="form-check-input" type="checkbox" name="extra" id="gridCheck2"
                        value={opcional}
                        onChange={(e) => setOpcional(e.target.checked)} />
                    </div>
                  </div>

                  {opcional &&
                   <Adicionales onSelectedItemsChange={handleSelectedItemsUpdate} ></Adicionales>}

                  <div className="text-center mt-3">
                    <button type="submit" className="btn btn-primary rounded-pill">
                      Registrar
                    </button>
                  </div>

                </form>

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}

