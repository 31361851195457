import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/AppContext";
import useInitialState from "../hooks/useInitialState";
import ItemCount from "../components/itemCount";
import { useCookies } from "react-cookie";

export default function Productos(props) {
  const [comentario, setComentario] = useState("");
  const [qty, setQty] = useState(0);
  const [img, setImg] = useState();
  const [usuario, setUsuario] = useCookies(["usuario"]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault(); // Evita que la página vuelva a la anterior
      const modal = document.getElementById(`exampleModal-${img}`);
      const modalClass = document.querySelector("body");
      const backdrop = document.querySelector(".modal-backdrop");

      if (modal) {
        modal.classList.remove("show");
        modal.classList.remove("hide");
        modal.style.display = "none";
        document.body.classList.remove("modal-open");

        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("padding-right");
      }

      // modal.classList.remove('modal');
      //modal.classList.remove('fade');
      if (backdrop) {
        backdrop.remove(); // eliminar el backdrop
      }
    };

    window.history.pushState(null, "", window.location.href); // Agrega una nueva entrada de historial para evitar volver atrás
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [img]);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  // Estado para manejar los radios (ingredientes con máximo <= 1)
  const [selectedRadio, setSelectedRadio] = useState(null);

  // Función para manejar la selección de checkboxes
  const handleCheckboxSelect = (ingrediente) => {
    setSelectedCheckboxes(
      (prevSelected) =>
        prevSelected.includes(ingrediente.id)
          ? prevSelected.filter((id) => id !== ingrediente.id) // Deselecciona si ya está seleccionado
          : [...prevSelected, ingrediente.id] // Selecciona si no está seleccionado
    );
  };

  // Función para manejar la selección de radios
  const handleRadioSelect = (ingrediente) => {
    setSelectedRadio(ingrediente.id); // Solo selecciona un radio
  };

  return (
    <div className="col-lg-6 menu-item" data-categoria={props.categoria}>
      <img
        src={props.img}
        loading="lazy"
        className="menu-img"
        data-bs-toggle="modal"
        id="menumodal"
        onClick={() => setImg(props.id)}
        data-bs-target={`#exampleModal-${props.id}`}
      />
      <a className="categoriaMenu">{props.categoria}</a>

      <div className="menu-content">
        <a>{props.nombre}</a> <span>${props.price}</span>
      </div>
      {/*   */}
      <div className="menu-ingredients">
        {props.ingredients}{" "}
        {props?.adicionales || usuario?.usuario?.plan?.id === 3 ? (
          <i
            class="bi bi-zoom-in"
            id="add"
            onClick={() => setImg(props.id)}
            data-bs-target={`#exampleModal-${props.id}`}
            data-bs-toggle="modal"
          >
            {" "}
          </i>
        ) : (
          <ItemCount
            img={props.img}
            categoria={props.categoria}
            nombre={props.nombre}
            price={props.price}
            stock={props.stock}
            initial={1}
            id={props.id}
          />
        )}
      </div>

      <div
        className="modal fade"
        id={`exampleModal-${props.id}`}
        tabIndex="-1"
        aria-labelledby={`exampleModalLabel-${props.id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content modal-contentMenu">
            <div className="modal-header">
              <span
                data-bs-dismiss="modal"
                className="btn-close material-icons-outlined"
                translate="no"
              >
                close
              </span>
            </div>

            <div className="modal-body modal-bodyMenu">
              <div
                className="accordion accordion-flush"
                id={`accordionFlushExample-${props.id}`}
              >
                <div className="gslide-media gslide-image">
                  <img
                    src={props.img}
                    loading="lazy"
                    className="zoomable imgModal"
                    data-bs-toggle="modal"
                    data-bs-target={`#exampleModal-${props.id}`}
                  />
                </div>
                <div className="flex pl-1">
                  <h5 className="colorprimaryMenu">{props.nombre}</h5>{" "}
                  <h5>${props.price}</h5>
                </div>
                <h6 className="menu pl-1 letter-gris">
                  Ingredientes: {props?.ingredients && props?.ingredients}
                </h6>

                {Object.entries(
                  (props.ingrediente || []).reduce((acc, ingrediente) => {
                    // Crear clave única para agrupar por subtipo, tipo, y máximo permitido
                    const key = `${ingrediente.subtipo?.nombre}-${ingrediente.tipo}-${ingrediente.maximo}`;

                    // Si no existe este grupo, crearlo
                    if (!acc[key]) {
                      acc[key] = {
                        subtipo: ingrediente.subtipo?.nombre,
                        tipo: ingrediente.tipo,
                        maximo: ingrediente.maximo,
                        ingredientes: [],
                      };
                    }

                    // Agregar el ingrediente al grupo correspondiente
                    acc[key].ingredientes.push(ingrediente);

                    return acc;
                  }, {})
                ).map(([key, group], groupIndex) => (
                  <div key={groupIndex} className="mb-2">
                    <div className="row mt-1">
                      <div className="col-12">
                        <div className="d-flex align-items-center justify-content-around p-2 rounded">
                          <h6 className="text-left m-0 text-uppercase subtipo primaryMenu">
                            <strong>
                              {group.tipo === "OPCIONAL"
                                ? "Extras"
                                : group.subtipo}
                            </strong>
                          </h6>
                          <div className="maximo letter-gris">
                            {group.tipo === "OPCIONAL"
                              ? "Otros le sumaron:"
                              : "Requerido. Elegir: " + group.maximo}
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="list-group bg-transparent">
                      {/* Mapear los ingredientes dentro del grupo */}
                      {group.tipo === "OPCIONAL" ? (
                        <ul className="list-group">
                          {group.ingredientes.map((ingrediente, index) => (
                            <li
                              className={`list-group-item d-flex justify-content-between align-items-center bg-transparent text-secondary pb-1 ${
                                selectedCheckboxes.includes(ingrediente.id)
                                  ? ""
                                  : ""
                              }`}
                              onClick={() => handleCheckboxSelect(ingrediente)}
                              style={{ cursor: "pointer" }}
                              key={ingrediente.id || index}
                            >
                              <div className="form-check pl-5 flex">
                                {/* Evaluamos si ingrediente.maximo es mayor a 1 */}
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`ingrediente${ingrediente.id}`}
                                  value={ingrediente.nombre}
                                  name={
                                    ingrediente.maximo > 1
                                      ? `ingrediente_${ingrediente.id}`
                                      : "ingredientes"
                                  } // Asigna diferentes nombres para checkbox y radio
                                  checked={
                                    selectedCheckboxes.includes(ingrediente.id) // Verifica si el checkbox está seleccionado
                                  }
                                  onChange={() =>
                                    handleCheckboxSelect(ingrediente)
                                  }
                                />
                                <label
                                  className="form-check-label text-light"
                                  htmlFor={`ingrediente${ingrediente.id}`}
                                >
                                  {ingrediente.nombre}
                                </label>
                              </div>
                              <h6 className=" yellow badge-primary badge-pill ml-3  text-dark">
                                + ${ingrediente.costo.toFixed(0)}
                              </h6>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        group.ingredientes.map((ingrediente, index) => (
                          <li
                            className={`list-group-item d-flex justify-content-between align-items-center bg-transparent text-secondary pb-1 ${
                                selectedRadio ===  ingrediente.id
                                ? ""
                                : ""
                            }`}
                            onClick={() =>
                              ingrediente.maximo > 1
                                ? handleCheckboxSelect(ingrediente)
                                : handleRadioSelect(ingrediente)
                            }
                            style={{ cursor: "pointer" }}
                            key={ingrediente.id || index}
                          >
                            <div className="form-check pl-5">
                              {/* Evaluamos si ingrediente.maximo es mayor a 1 */}
                              <input
                                className="form-check-input"
                                type={
                                  ingrediente.maximo > 1 ? "checkbox" : "radio"
                                } // Condicional para cambiar entre radio y checkbox
                                id={`ingrediente${ingrediente.id}`}
                                value={ingrediente.nombre}
                                name={
                                  ingrediente.maximo > 1
                                    ? `ingrediente_${ingrediente.id}`
                                    : "ingredientes"
                                } // Asigna diferentes nombres
                                required={ingrediente.maximo <= 1} // Requiere selección en radio
                                checked={
                                  ingrediente.maximo > 1
                                    ? selectedCheckboxes.includes(
                                        ingrediente.id
                                      ) // Verifica si el checkbox está seleccionado
                                    : selectedRadio === ingrediente.id // Verifica si el radio está seleccionado
                                }
                                onChange={() =>
                                  ingrediente.maximo > 1
                                    ? handleCheckboxSelect(ingrediente)
                                    : handleRadioSelect(ingrediente)
                                }
                              />
                              <label
                                className="form-check-label text-light"
                                htmlFor={`ingrediente${ingrediente.id}`}
                              >
                                {ingrediente.nombre}
                              </label>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                ))}

                {usuario?.usuario?.plan?.id !== 3 && (
                  <>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="1"
                        placeholder="Aclaraciones"
                        onChange={(e) => setComentario(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="center colorprimary">
                      <ItemCount
                        className="colorprimary"
                        img={props.img}
                        categoria={props.categoria}
                        nombre={props.nombre}
                        price={props.price}
                        stock={props.stock}
                        initial={1}
                        id={props.id}
                        comentario={comentario}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* {
                                        cart.card.map((props) => (

                                            <>


                                                <div >
                                                    <span onClick={() => {
                                                        if (props.qty == 1) return;
                                                        // setShowError(true);
                                                        restToCart(props, -1);
                                                    }}
                                                        // disabled={qty - props.qty <= 0 ? true :  false} 
                                                        className="material-symbols-outlined">
                                                        remove_circle_outline
                                                    </span>

                                                    <span id="cantidad" className='colorprimary'>{props.qty} </span>





                                                </div>

                                              

                                            </>))} */
}
