import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";


export default function Subcripcion() {

  const [usuario] = useCookies(['usuario']);
  const [isLoading, setIsLoading] = useState(false);
  const whatsappLink = `https://wa.me/+549${usuario?.usuario?.whatsapp}`;
  const mensaje = "¡Hola! Me gustaría sumarme a CLICKMENU. ¿Cómo puedo iniciar con el Combo ";
  const mensajeCodificado = encodeURIComponent(mensaje);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    
    const userEmail = usuario.usuario.email
    const subject = encodeURIComponent(event.target.subject.value);
    const message = encodeURIComponent(event.target.message.value);

    // Construir el enlace para abrir Gmail con el mensaje predefinido
    const gmailLink = `https://mail.google.com/mail/?view=cm&to=${userEmail}&su=${subject}&body=${message}`;

    const outlookLink = `https://outlook.office365.com/mail/deeplink/compose?to=${userEmail}&subject=${subject}&body=${message}`;

    // Abrir Gmail en una nueva pestaña
    window.open(outlookLink, '_blank');

    // Actualizar el estado para mostrar el mensaje enviado
   // setOk(200);
    setIsLoading(false);
  };

  return (

    usuario.usuario ?

      <section id="contact" className="sectionMenu contact">
    

{/*         <div className="container" data-aos="fade-up">

          <div className="section-title">
           >
          </div>
        </div>    <div data-aos="fade-up">
          <iframe className="mapMenu" src={usuario.usuario.map} frameborder="0" allowfullscreen></iframe>
        </div>*/}
        <div className="container"> 
        <section id="pricing" className="pricing section">

            <div className="container section-title aos-init aos-animate" data-aos="fade-up">
            <h2>Nuestro Menú</h2>
            <p>Precios Promocionales</p>
            </div>

            <div className="container aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">

              <div className="grid g-4">

                <div className="col-lg-4 ">
                  <div className="pricing-item">
                    <h3>Combo ENTRADA</h3>
                    <div className="icon">
                      <i className="bi bi-box"></i>
                    </div>
                    <div className="center"><h5 class="text-decoration-line-through"><span></span><sup >$</sup>22.000</h5>
                     <h4><sup>$</sup>14.000<span> / mes</span><h6>Anual</h6></h4></div>
                    <ul>
                      <li><i className="bi bi-check"></i> <span>Primer Mes Gratis</span></li>
                      <li><i className="bi bi-check"></i> <span>Menú Personalizado </span></li>
                      <li><i className="bi bi-check"></i> <span>Sistema Administrador ALIADOS</span></li>
                      <li className="na"><i className="bi bi-x"></i> <span>Pedidos en Local + Delivery  + Take Away</span></li>
                      <li className="na"><i className="bi bi-x"></i> <span>Sin comisiones por pedido</span></li>
                      <li className="na"><i className="bi bi-x"></i> <span>Personalización + Estadisticas</span></li>
                    </ul>
                    <div className="text-center"><button className="buy-btn"onClick={() => window.open(`${whatsappLink}?text=${mensajeCodificado}ENTRADA?`, '_blank')}>
                    SUMARME</button></div>
                  </div>
                </div>

                <div className="col-lg-4 m-5">
                  <div className="pricing-item featured">
                    <h2>Combo PRINCIPAL</h2>
                    <div className="icon">
                      <i className="bi bi-rocket"></i>
                    </div>
                    <div className="center"><h5 class="text-decoration-line-through"><span></span><sup >$</sup>35.000 / mes a mes</h5>
                   <h4> <sup>$</sup>26.000<span> / mes<h6>Anual</h6></span></h4></div>
                    <ul>
                      <li><i className="bi bi-check"></i> <span>Primer Mes Gratis</span></li>
                      <li><i className="bi bi-check"></i> <span>Menú Personalizado</span></li>
                      <li><i className="bi bi-check"></i> <span>Sistema Administrador ALIADOS</span></li>
                      <li><i className="bi bi-check"></i> <span>Pedidos en Local + Delivery  + Take Away</span></li>
                      <li><i className="bi  bi-check"></i> <span>Sin comisiones por pedido</span></li>
                      <li className="na"><i className="bi bi-x"></i> <span>Personalización + Estadisticas</span></li>
                    </ul>
                    <div className="text-center">
                      <button className="buy-btn" onClick={() => window.open(`${whatsappLink}?text=${mensajeCodificado}PRICIPAL?`, '_blank')}
                      >SUMARME</button></div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="pricing-item">
                    <h3>Combo POSTRE</h3>
                    <div className="icon">
                      <i className="bi bi-send"></i>
                    </div>
                    <h4>Plus</h4> 
                    {/* <h4><sup>$</sup>49<span> / month</span></h4> */}
                    <ul>
                      <li><i className="bi bi-check"></i> <span>Primer Mes Gratis</span></li>
                      <li><i className="bi bi-check"></i> <span>Menú Personalizado </span></li>
                      <li><i className="bi bi-check"></i> <span>Sistema Administrador ALIADOS</span></li>
                      <li><i className="bi bi-check"></i> <span>Pedidos en Local + Delivery  + Take Away</span></li>
                      <li ><i className="bi bi-check"></i> <span>Sin comisiones por pedido</span></li>
                      <li><i className="bi bi-check"></i> <span>Personalización + Estadisticas</span></li>
                    </ul>
                    <div className="text-center"><button className="buy-btn"  onClick={() => window.open(`${whatsappLink}?text=${mensajeCodificado}POSTRE?`, '_blank')}>CONSULTAR</button></div>
                  </div>
                </div>

              </div>

            </div>

            </section>

</div>
      </section>
      : <></>
  )
}